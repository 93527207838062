const headerItems = document.querySelectorAll(".header__menu_item_dropdown")
headerItems.forEach(item => {
    item.addEventListener("click", () => {
        if (item.parentElement.classList.contains("active")) {
            headerItems.forEach(item => {
                item.parentElement.classList.remove("active")
                document.querySelector(".header__menu").classList.remove("open-child")
            })
            document.body.classList.remove("open-menu")
        } else {
            headerItems.forEach(item => {
                item.parentElement.classList.remove("active")
            })
            item.parentElement.classList.add("active")
            document.body.classList.add("open-menu")
            document.querySelector(".header__menu").classList.add("open-child")
        }
    })
})
document.querySelector(".header_overlay").addEventListener("click", () => {
    headerItems.forEach(item => {
        item.parentElement.classList.remove("active")
    })
    document.body.classList.remove("open-menu")
    document.querySelector(".header__menu").classList.remove("open")
    document.querySelector(".header__menu").classList.remove("open-child")
})
const mobileMenuBtn = document.querySelector(".header__open-menu")
const closeMobileMenu = document.querySelector(".header__menu_title_close")
const backMobileMenu = document.querySelectorAll(".header__child_menu__back")
mobileMenuBtn.addEventListener("click", () => {
    headerItems.forEach(item => {
        item.parentElement.classList.remove("active")
    })
    document.body.classList.toggle("open-menu")
    document.querySelector(".header__menu").classList.remove("open-child")
    document.querySelector(".header__menu").classList.toggle("open")
})
closeMobileMenu.addEventListener("click", () => {
    headerItems.forEach(item => {
        item.parentElement.classList.remove("active")
    })
    document.body.classList.remove("open-menu")
    document.querySelector(".header__menu").classList.remove("open")
})
backMobileMenu.forEach(item => {
    item.addEventListener("click", () => {
        headerItems.forEach(item => {
            item.parentElement.classList.remove("active")
        })
        document.querySelector(".header__menu").classList.remove("open-child")
    })
})
